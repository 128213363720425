/** =================== CABIN =================== **/

/** Cabin Regular 400 **/
@font-face {
    font-family: 'Cabin';
    font-weight: 400;
    font-style: normal;
    src: url("../fonts/Cabin/Cabin-Regular-400.woff2") format("woff2");
}

/** Cabin Medium 500 **/
@font-face {
    font-family: 'Cabin';
    font-weight: 500;
    font-style: normal;
    src: url("../fonts/Cabin/Cabin-Medium-500.woff2") format("woff2");
}

/** Cabin SemiBold 600 **/
@font-face {
    font-family: 'Cabin';
    font-weight: 600;
    font-style: normal;
    src: url("../fonts/Cabin/Cabin-Semi-bold-600.woff2") format("woff2");
}

/** Cabin Bold 700 **/
@font-face {
    font-family: 'Cabin';
    font-weight: 700;
    font-style: normal;
    src: url("../fonts/Cabin/Cabin-Bold-700.woff2") format("woff2");
}