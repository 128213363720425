/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import '~animate.css/animate.min';

:root {
    --primary-color: #5F5F5F;
    --secondary-color: #F07600;
    --third-color: #8F8F8F;
    --fourth-color: #C4C4C4;
    --white-color: #FFFFFF;
    --available-color: #11A00A;
    --booked-color: #E30000;
    --unavailable-color: #A6A6A6;
    --color-bg: #FBFBFB;
    --color-bg-white: #FFFFFF;
    --color-bg-third: #282F39;
    --color-bg-fourth: #535A64;
}

body,
html {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

* {
  font-family: 'cabin', sans-serif;
}

.page-container {
  width: 100%;
  min-height: 100vh;
}

.sidenav-container {
  background-color: var(--color-bg-third) !important;
}

.color-white {
  color: var(--white-color);
}

/* width */
::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: var(--color-bg-white);
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color-bg-fourth);
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--color-bg-fourth);
}

.spacer {
  width: 100%;
  height: 15px;
  background-color: var(--color-bg);
  z-index: 1;
}

.pointer {
  cursor: pointer;
}

.opacity-7 {
  opacity: .7;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--secondary-color) !important;
  stroke-width: 2px !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.dialog-confirm .mat-dialog-container {
  width: 30vw !important;
  border: 5px !important;
  border-radius: 10px !important;
}

.dialog-confirmation .mat-dialog-container {
  width: 70vw !important;
  border: 5px !important;
  border-radius: 10px !important;
}

.dialog-filter-status .mat-dialog-container {
  width: 30vw !important;
  border: 5px !important;
  border-radius: 10px !important;
  height: 370px;
}

.cancel-dialog .mat-dialog-container {
  width: 460px !important;
  border: 5px !important;
  border-radius: 10px !important;
  height: 520px;
}

.reject-dialog .mat-dialog-container {
  width: 485px !important;
  border: 5px !important;
  border-radius: 10px !important;
  height: 490px;
}

.table-details .mat-dialog-container {
  width: 85vw;
  border-radius: 10px !important;
}

.status-confirmed {
  background: -webkit-linear-gradient(#11A00A, #A2CA29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.div-status-confirmed {
  background: -webkit-linear-gradient(#11A00A, #A2CA29);
  font-weight: 600;
}

.status-finished {
  background: -webkit-linear-gradient(#2F2E87, #B34AF3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.div-status-finished {
  background: -webkit-linear-gradient(#2F2E87, #B34AF3);
  font-weight: 600;
}

.status-new {
  background: -webkit-linear-gradient(#0A51B0, #0892F5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.div-status-new {
  background: -webkit-linear-gradient(#0A51B0, #0892F5);
  font-weight: 600;
}

.status-rejected {
  background: -webkit-linear-gradient(#F07600, #D02886);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.div-status-rejected {
  background: -webkit-linear-gradient(#F00000, #D02886);
  font-weight: 600;
}

.status-cancelled {
  background: -webkit-linear-gradient(#5F5F5F, #5F5F5F);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.div-status-cancelled {
  background: -webkit-linear-gradient(#5F5F5F, #5F5F5F);
  font-weight: 600;
}

.panel-header {
    position: fixed;
    width: calc(100% - 250px);
    background-color: white;
    color: var(--primary-color);
    font-size: 1rem;
    padding: 1rem 2rem 1rem 2rem;
    z-index: 1;
}

.panel-header.expand {
    padding-left: 2rem !important;
    width: 100%;
}

/* Half Screen Dialog */
.half-screen-dialog{
  max-width: 50vw !important;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute !important;
  right: 0;
  animation-duration: 500ms !important;
  animation-delay: 0ms !important;
}

.half-screen-dialog .mat-dialog-container{
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.search-input-branch {
  text-align: left;
  margin: 15px 10px 0 15px;
  padding: 10px;
  width: 93%;
  background-color: var(--white-color);
  border-radius: 10px;
  outline: 0;
}

.no-data-img {
  margin-bottom: 10px;
  width: 165px;
}